@import url('https://fonts.googleapis.com/css2?family=Georgia&display=swap');

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
  font-family: 'Georgia', serif;
}

.about-container {
    background-color: #f5f5f5;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .section-header {
    margin-bottom: 40px;
    text-align: center;
  }
  
  .section-header h2 {
    font-size: 32px;
    color: #e91e63;
    margin-bottom: 10px;
  }
  
  .section-header p {
    font-size: 18px;
    color: #555;
  }
  
  .service-item {
    margin-bottom: 30px;
  }
  
  .service-item h3 {
    font-size: 24px;
    color: #009688;
    margin-bottom: 10px;
  }
  
  .service-item p {
    font-size: 16px;
    color: #777;
  }
  
  .contact-info {
    margin-top: 40px;
    text-align: center;
  }
  
  .contact-info h3 {
    font-size: 24px;
    color: #ff5722;
    margin-bottom: 10px;
  }
  
  .contact-info p {
    font-size: 16px;
    color: #777;
    margin-top: 5px;
  }

  .musalla_img {
    display: block;
    object-fit: contain;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    
  }
  