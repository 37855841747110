.prayer-times-container {
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
    font-size: 14px;
    color: #333;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: 0 auto;
  }
  
  th,
  td {
    padding: 12px;
    text-align: center;
    border: 1px solid #e0e0e0;
  }
  
  th[colSpan="2"] {
    font-size: 18px;
    font-weight: bold;
    text-transform: uppercase;
  }
  
  td:first-child {
    font-weight: bold;
    vertical-align: middle; 
  }
  
  i {
    margin-right: 5px;
  }
  
  td:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    border: 0px;
    padding-top: 1.3rem;
  }
  
  .time-to-pray {
    color: #e74c3c;
  }
  
  .upcoming {
    color: #3498db;
  }
  
  tbody tr:nth-child(even) {
    background-color: #fafafa;
  }
  
  tbody tr:hover {
    background-color: #f1f1f1;
  }
  
  h2 {
    margin-bottom: 20px;
    text-align: center;
    color: #333;
    font-size: 24px;
    text-transform: uppercase;
  }

  .icon-container {
    display: flex;
    align-items: 'center';
    justify-content: 'center';
  }
  
  .prayer-text {
    padding-left: 1rem;
  }