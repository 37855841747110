@import url('https://fonts.googleapis.com/css2?family=Georgia&display=swap');

* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
  font-family: 'Georgia', serif;
}

.support-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .volunteer-section,
  .donate-section {
    margin-top: 80px;
    margin-bottom: 40px;
  }
  
  .volunteer-section h2,
  .donate-section h2 {
    font-size: 32px;
    color: #e91e63;
    margin-bottom: 10px;
  }
  
  .volunteer-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  label {
    font-size: 18px;
    color: #333;
    margin-bottom: 5px;
  }
  
  input[type="text"],
  input[type="email"],
  textarea {
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-left: 10px;
    width: 89%;
    padding-left: 5px;
  }

  input[type="text"] {
    margin-left: 33px;
  }

  input[type="email"] {
    margin-left: 35px;
  }

  textarea {
    width: 89%;
    height: 200px;
  }
  
  button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #e91e63;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #c2185b;
  }
  
  .donate-form .form-group label {
    display: block;
  }
  
  .donate-form #card-element {
   border: 1px solid #ccc;
    padding: 10px;
    margin-top: 5px;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .donation-info {
    margin-top: 20px;
  }
  
  .donation-info h3 {
    font-size: 20px;
    color: #333;
    margin-bottom: 10px;
  }
  
  .donation-info p {
    font-size: 16px;
    color: #777;
    margin-top: 5px;
  }
  