.home_container {
    width: 100vw;
}

.hero {
    display: flex;
    justify-content: center;
    width: 100vw;
}

.hero_container {
    position: relative;
      display: inline-block;
      width: 100vw;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 80vh;
    background-color: rgba(0, 0, 0, 0.5); 
    z-index: 1;
  }

.hero_img {
    display: block;
    width: 100vw;
    height: 80vh;
}

.title {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: white;
      font-size: 50px;
      text-align: center;
      z-index: 2;
      
}




p {
    font-size: large;
} 

.home_content {
    width: 75%;
    margin: auto;
    height: 100vh;
}

.home_container .paragraph1 {
    text-align: center;
}

.paragraph1 {
    margin: 50px 0px;
}

.donate_section {
    display: flex;
}

.donate_section_button {
    margin-left: 75%;
    margin-top: 50px;
    padding: 10px 20px;
    background-color: #29335c;
    text-decoration: none;
    color: white;
}

.widget-container {
}