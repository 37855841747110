
@import url('https://fonts.googleapis.com/css2?family=Georgia&display=swap');

* {
    font-family: 'Georgia', serif;
    box-sizing: border-box;
	padding: 0;
	margin: 0;
}



.events {
    max-width: 800px;
    margin: 0 auto;
}

.events__section__photos__grid {
    display: grid;

    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px; /* Gap between grid items */
    padding: 0px 15px;
}



@media (min-width: 600px) {
    .events__section__photos__grid { grid-template-columns: repeat(2, 1fr); }
  }

  @media (min-width: 900px) {
    .events__section__photos__grid { grid-template-columns: repeat(3, 1fr); }
  }

.events__section__photos {
    display: flex;
    justify-content: center;
}

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.events__section {
    /* margin: 100px 40px; */
}
/* 
.events {
    width: 100%;
    display: flex;
    justify-content: center;
} */


.event-header {
    font-size: 24px;
    font-weight: bold;
    color: #333;
}
.event-details {
    font-size: 18px;
    margin-top: 10px;
    color: #555;
    
}
.event-details strong {
    margin-top: 10px;
}

.events__section__description {
    font-family: Arial, sans-serif;
    margin: 20px;
}

