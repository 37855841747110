/* * {
    margin: 0;
}

body {
    margin: auto;
    width: 50%;
    font-family: Verdana, sans-serif;

}

.App {
    background-color: aliceblue;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
    height: 100vh;
} */

.App {
    max-width: 100%;
    width: 100vw;
    font-family: Verdana, sans-serif;
}

html {
    overflow-y: scroll; overflow-x: hidden;
}